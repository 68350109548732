import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import gsap from 'gsap';

/**
 * Base
 */
// Debug
const gui = new dat.GUI();
const params = {
    cube1: {
        segments: 1
    },
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/3.png')
const materialMatcapWhite = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
const materialBasicWhite = new THREE.MeshBasicMaterial({ color: 'lightgray' })

// Axes helper
// scene.add(new THREE.AxesHelper());

const getCubeGeometry = () => {
    return new THREE.BoxGeometry(0.125, 0.55, 0.125, params.cube1.segments, params.cube1.segments, params.cube1.segments);
}

const cubeMesh = new THREE.Mesh(getCubeGeometry(), materialBasicWhite)
cubeMesh.material.wireframe = true
cubeMesh.name = 'box_1'
scene.add(cubeMesh)
cubeMesh.position.x = 0
cubeMesh.position.y = 0.25
cubeMesh.position.z = 0
gui.add(params.cube1, 'segments', 1, 100, 1).onChange(() => {
        cubeMesh.geometry = getCubeGeometry()
    }
)
gui.add(cubeMesh.position, 'x', -5, 5)
gui.add(cubeMesh.position, 'y', -5, 5)
gui.add(cubeMesh.position, 'z', -5, 5)
gui.hide();


const fontLoader = new FontLoader();

let hiMesh = null
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const getTextGeometry = () => {
            return new TextGeometry(
                'Hi',
                {
                    font,
                    size: 0.5,
                    height: 0.2,
                    bevelEnabled: true,
                    bevelThickness: 0.03,
                    bevelSize: 0.02,
                    bevelOffset: 0,
                    bevelSegments: 6,
                }
            );
        }
        // 'Hi, I\'m Armin'
        const textGeometry = getTextGeometry();
        // textGeometry.center();
        // gui.add(textMaterial, 'wireframe')
        const textMesh = new THREE.Mesh(textGeometry, materialMatcapWhite);
        textMesh.position.x = -0.035
        textMesh.position.z = -0.1
        textMesh.name = 'hi_text';
        hiMesh = textMesh
    }
);

let arminMesh = null
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const getTextGeometry = () => {
            return new TextGeometry(
                'I\'m Armin',
                {
                    font,
                    size: 0.5,
                    height: 0.2,
                    bevelEnabled: true,
                    bevelThickness: 0.03,
                    bevelSize: 0.02,
                    bevelOffset: 0,
                    bevelSegments: 6,
                }
            );
        }
        // 'Hi, I\'m Armin'
        const textGeometry = getTextGeometry();
        // textGeometry.center();
        // gui.add(textMaterial, 'wireframe')
        const textMesh = new THREE.Mesh(textGeometry, materialMatcapWhite);
        textMesh.position.x = 9
        textMesh.position.z = -0.1
        arminMesh = textMesh
    }
);

let softwareMesh = null
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const fontParams = {
            font,
            size: 0.5,
            height: 0.2,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 6
        }
        const textGeometry = new TextGeometry(
            'Software Engineer.',
            fontParams
        );
        textGeometry.center();
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
        const text = new THREE.Mesh(textGeometry, textMaterial);
        text.position.y = 0
        text.position.x = 10
        softwareMesh = text;
    }
);

let creatorMesh = null
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const fontParams = {
            font,
            size: 0.5,
            height: 0.2,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 6
        }
        const textGeometry = new TextGeometry(
            'Creator.',
            fontParams
        );
        textGeometry.center();
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
        const text = new THREE.Mesh(textGeometry, textMaterial);
        text.position.y = -0.8
        text.position.x = 8
        creatorMesh = text;
    }
);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(60, sizes.width / sizes.height, 0.02, 100)
camera.position.x = -0.23
camera.position.y = 1
camera.position.z = 3
camera.position.set(0, 0.2667773144472574, 0)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let counter2 = 1;
let stopAnimation2 = false;
const desktop = window.innerWidth > 1000;

if (desktop) {
    gsap.to(camera.position, { duration: 2.572, delay: 0, y: 0.6 })
    gsap.to(camera.position, { duration: 2, delay: 1.5, z: 0.03 })

    gsap.to(camera.position, { duration: 1.45, delay: 2.55, y: 1.5 })
    gsap.to(camera.position, { duration: 2.45, delay: 2.55, z: 3 })
    gsap.to(camera.position, { duration: 2.45, delay: 2.55, x: 1 })

    setTimeout(() => {
        gsap.to(hiMesh.position, { duration: 1, delay: 3, x: -2.3 })
        gsap.to(arminMesh.position, { duration: 1, delay: 3, x: -1.5 })
        gsap.to(hiMesh.position, { duration: 1, delay: 3, y: 0.8 })
        gsap.to(arminMesh.position, { duration: 1, delay: 3, y: 0.8 })


        gsap.to(softwareMesh.position, { duration: 1.5, delay: 3.5, x: -0.6 })
        gsap.to(creatorMesh.position, { duration: 1.5, delay: 4, x: -0.6 })
    }, 1000)

    gsap.to(camera.position, { duration: 1, delay: 4, y: -0.8 })

} else {
    gsap.to(camera.position, { duration: 2.572, delay: 0, y: 0.6 })
    gsap.to(camera.position, { duration: 2, delay: 1.5, z: 0.03 })

    gsap.to(camera.position, { duration: 1.45, delay: 2.55, y: 1.5 })
    gsap.to(camera.position, { duration: 2.45, delay: 2.55, z: 12 })
    gsap.to(camera.position, { duration: 2.45, delay: 2.55, x: 4 })

    setTimeout(() => {
        gsap.to(hiMesh.position, { duration: 1, delay: 3, x: -1.9 })
        gsap.to(arminMesh.position, { duration: 1, delay: 3, x: -1.1 })
        gsap.to(hiMesh.position, { duration: 1, delay: 3, y: 0.7 })
        gsap.to(arminMesh.position, { duration: 1, delay: 3, y: 0.7 })


        gsap.to(softwareMesh.position, { duration: 1.5, delay: 3.5, x: -0.2 })
        gsap.to(creatorMesh.position, { duration: 1.5, delay: 4, x: -0.2 })
    }, 1000)

    gsap.to(camera.position, { duration: 1, delay: 4, y: -1.8 })
}


const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // cube lines and replacement
    if (elapsedTime > counter2 / 16) {
        if (!stopAnimation2) {
            if (counter2 > 15) {
                params.cube1.segments++
                cubeMesh.geometry = getCubeGeometry()
            }
            if (counter2 > 40) {
                console.log(elapsedTime)
                cubeMesh.material = materialMatcapWhite
                stopAnimation2 = true;
                if (hiMesh) scene.add(hiMesh)
                scene.remove(scene.getObjectByName('box_1'))
                if (arminMesh) scene.add(arminMesh)
                if (softwareMesh) scene.add(softwareMesh)
                if (creatorMesh) scene.add(creatorMesh)
            }
        }
        counter2++
    }

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()